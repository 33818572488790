import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import SectionTitle from '@ui/section-title'
import { Link } from 'gatsby'
import Heading from '@ui/heading'
import Text from '@ui/text'
import Button from '@ui/button'
import Anchor from '@ui/anchor'
import Image from '@ui/image'

import {
  CaseStudyWrap,
  CasestudyContentwrap,
  ImageWrap,
  CaseStudyTextWrap,
  ButtonWrapTop,
  ButtonWrap
} from './style'
import { navigate } from "@reach/router"
const CaseStudy = ({
  HeadingStyle,
  data,
  dataSinglePage,
  layout,
  ...props
}) => {
  return (
    <CaseStudyWrap layout={layout} {...props} id={data.section}>
      <Container>
        <Row className='align-items-center'>
          <Col lg={8}>
            <SectionTitle
              title={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.title
                  : data.section_title?.title
              }
              subtitle={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.subtitle
                  : data.section_title?.subtitle
              }
              description={
                dataSinglePage?.section_title
                  ? dataSinglePage.section_title.description
                  : data.section_title?.description
              }
            />
          </Col>
          <Col lg={4}>
            <ButtonWrapTop>
              {data?.buttons?.map(({ id, content, ...rest }) => (
                <Button key={id} m='7px' {...rest}>
                  {content}
                </Button>
              ))}
            </ButtonWrapTop>
          </Col>
        </Row>

        <Row>
          {data &&
            data.items.map((el, index) => (
              <Col xl={4} lg={6} key={index}>
                <CasestudyContentwrap onClick={() => { navigate(el.path) }}>
                  <ImageWrap>
                    <Image
                      src={el.images[0].src}
                      alt={el.images[0]?.alt || 'Icon'}
                    />
                  </ImageWrap>
                  <CaseStudyTextWrap>
                    <Heading as='h6'>{el.title}</Heading>
                    <Text>{el.description}</Text>
                    <ButtonWrap>
                      <Anchor as={Link} to={el.path} key={index}>
                        READ MORE
                      </Anchor>
                    </ButtonWrap>
                  </CaseStudyTextWrap>
                </CasestudyContentwrap>
              </Col>
            ))}
        </Row>
      </Container>
    </CaseStudyWrap>
  )
}

CaseStudy.propTypes = {
  layout: PropTypes.oneOf([1, 2])
}

CaseStudy.defaultProps = {
  layout: 1
}

export default CaseStudy
